body {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 500px;
}

.App p {
  color: white;
}

.pot {
  display: flex;
  align-items: center;
  width: 44%;
  justify-content: center;
  flex-direction: column;
  gap: 14px;
}

.nouv2, .nouv {
  font-weight: bold;
  font-size: 20px;
}

.nouv div, .nouv2 div {
  justify-content: space-between;
}

.nouv, .nouv2 {
  width: 100%;
  display: flex;
  align-items: left;
}


.nouv2 {
  justify-content: right;
}

.nouv > div, .nouv2 > div {
  
  background: #70FF2E;
  box-shadow: 0 0 0px 0px #70FF2E;
  display: flex;
  margin-left: -30px;
  width: 150px;
  align-items: center;
  border-radius: 20px;

}

.nouv2 > div {
  
  background: #F55353;
  box-shadow: 0 0 0px 0px  #F55353;
  margin-right: -30px;
  margin-left: 0;
}

.nouv img, .nouv object, .nouv2 object, .nouv2 img {
  z-index: 1;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  background: white;
}

.divide2 {
  display:flex;
  margin-top: 30px;
  width: 100%;
  flex-direction: row;
  justify-content: center;
}

.fear {
  margin: 0;
  font-size: 60px;
  margin-top: 0;
  font-family: Courier, monospace;
}

.balance-item {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 5px 10px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 30px;
  z-index: 1;
  font-size: 25px;
  font-weight: bold;
}

.balance-item:before {
  z-index: 0;
  position: absolute;
  content: "";
  top: -16px;
  background: linear-gradient(white 23%, rgba(0,0,0,0) 24%, rgba(0,0,0,0) 76%, white 77%);
  width: 4px;
  transform: translateX(1px);
  height: 71px;
}



.balance {
  border: 1px solid white;
  margin-top: 45px;
  position: relative;
  width: 90%;
  height: 70px;
  border-radius: 10px;
  background: linear-gradient(90deg, rgba(43,43,43,1) 0%, rgba(255,7,0,1) 25%, rgba(255,200,0,1) 42%, rgba(254,255,0,1) 66%, rgba(173,250,95,1) 84%, rgba(18,255,0,1) 100%);
}