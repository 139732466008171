body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* {7 items
  "country":"united states"
  "name":"S&P 500"
  "full_name":"S&P 500"
  "symbol":"SPX"
  "currency":"USD"
  "class":"major_indices"
  "market":"world_indices"
  } 
  
  {7 items
"country":"united states"
"name":"DJ Global ex-US Select Real Estate"
"full_name":"Dow Jones Global ex-US Select Real Estate Securities"
"symbol":"DWXRS"
"currency":"USD"
"class":"other_indices"
"market":"global_indices"
}

{7 items
"country":"euro zone"
"name":"FTSE EUROFIRST 100 Market"
"full_name":"FTSE EUROFIRST 100 Market"
"symbol":"FTEF100C1R"
"currency":"EUR"
"class":"other_indices"
"market":"global_indices"
}

{7 items
"country":"china"
"name":"S&P/CITIC300"
"full_name":"S&P/CITIC300"
"symbol":"SPCITIC300"
"currency":"CNY"
"class":"additional_indices"
"market":"world_indices"
}

{7 items
"country":"brazil"
"name":"Brazil 50"
"full_name":"Bovespa Brazil 50"
"symbol":"IBX50"
"currency":"BRL"
"class":"additional_indices"
"market":"world_indices"
}

  
  
  
  
  */